export class Student {
    constructor(code, email, name, surname, typeOfPlan) {
        this.code = code;
        this.email = email;
        this.name = name;
        this.surname = surname;
        this.typeOfPlan = typeOfPlan;
    }
}

//module.exports = Student;